import React from "react"

const Bar = () => (
    <section className="border-grid">
         <div className="container12">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">-</div><div className="col-md-3">-</div>
                  <div className="col-md-3">-</div><div className="col-md-3">-</div>
                </div> 
              </div> 
        </div>
      </section>
)

export default Bar;