//import { Link } from "gatsby"
import React from "react"
import {Link} from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticQuery, graphql } from "gatsby"

var settings = {
   dots: false,
   infinite: true,
   speed: 500,
   slidesToShow: 4,
   slidesToScroll: 4,
   responsive: [
      {
         breakpoint: 1024,
         settings: {
           slidesToShow: 4,
           slidesToScroll: 4,
           infinite: true,
           dots: false
         }
       },
       {
         breakpoint: 992,
         settings: {
           slidesToShow: 2,
           slidesToScroll: 1,
           infinite: true,
           dots: false
         }
       },
       {
         breakpoint: 767,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 2,
           initialSlide: 2
         }
       },
       {
         breakpoint: 480,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1
         }
       }
   ]
 };

const Footer = (props) =>{
   console.log("Path", props.path);
   return( 
      <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "global-footer"}}) {
                  edges {
                    node {
                      slug
                      FooterContent {
                        copyrightText
                        footerMenu1
                        footerMenu2
                        footerMenu3
                        footerMenu4
                        clientReviews {
                          reviews {
                            ... on WpSocialReview {
                              id
                              content
                              SocialReviews {
                                comments
                                designation
                                company
                                source
                                sourceUrl
                                videoImage {
                                 sourceUrl
                                }
                              }
                              title
                            }
                          }
                          title
                        }
                        footerMenus {
                          ... on WpPage_Footercontent_FooterMenus_FooterMenus {
                            fieldGroupName
                            menu {
                              fontFamily
                              fontSize
                              linkColor
                              linkHoverColor
                              menuName
                            }
                          }
                        }
                        letSHaveAChat {
                          block {
                            description
                            link
                            title
                          }
                          title
                        }
                        ourLocations {
                          location {
                            address
                            buttonText
                            flag {
                              sourceUrl
                              title
                            }
                            locationLink
                            mapImage
                            name
                          }
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
              return(
               data && 
               data.allWpPage &&
               data.allWpPage.edges &&
               data.allWpPage.edges[0] &&
               data.allWpPage.edges[0].node &&
               data.allWpPage.edges[0].node.FooterContent &&
               <footer className="no-lines">
                  <section className={`${(props.path && props.path.split("/")[1]=== "blog") || props.path === "/blog/" || props.path === "/terms/" || props.path === "/privacy/"?'bg-address mt-0 blog-ft':'bg-address'}`}>
                    <div className="container">
                      <div className="row">
                        <Slider id="myaddCarousel" {...settings} className="services-items">
                           {
                           data.allWpPage.edges[0].node.FooterContent.ourLocations &&
                           data.allWpPage.edges[0].node.FooterContent.ourLocations.location.map((data,key)=>{
                              return(
                                 <div key={key} className="item">
                                       <div className="Address">
                                          <figure>
                                                <img src={data.flag.sourceUrl} alt="flag" /> 
                                          </figure>
                                          <h5 className="text-light ">{data.name}</h5>
                                          <p className="text-light mb-4">{data.address}</p>
                                          <div className="d-flex justify-content-between c-icon">
                                             <p className="text-light ">{data.buttonText}</p>
                                             <span dangerouslySetInnerHTML={{__html:data.mapImage}}></span>
                                          </div>
                                       </div>
                                   </div>
                                )
                           })}
                        </Slider>
                     </div>
                  </div>
               </section>
               <section className="footer-bg">
                  {data.allWpPage.edges[0].node.FooterContent.letSHaveAChat && 
                  <div className="container chat-sec">
                     <div className="row">
                        <div className="col-md-6 col-lg-3 pb-0">
                           <h2 className="text-light  chat-head">{data.allWpPage.edges[0].node.FooterContent.letSHaveAChat.title}</h2>
                        </div>
                        <div className="col-md-6 col-lg-3 pb-0"></div>
                        <div className="col-md-6 col-lg-3 pb-0"></div>
                        <div className="col-md-6 col-lg-3 pb-0"></div>
                     </div>
                     <div className="row">
                        {data.allWpPage.edges[0].node.FooterContent.letSHaveAChat
                        &&data.allWpPage.edges[0].node.FooterContent.letSHaveAChat.block.length>0
                        &&data.allWpPage.edges[0].node.FooterContent.letSHaveAChat.block.map((chat,key)=>{
                           return(
                              <div key={key} className="col-md-6 col-lg-3 col-sm-6">
                                 <div className="chat-inner">
                                    <div className="d-flex align-items-center">
                                       <h3 className="text-light mr-3">{chat.title}</h3>
                                       <span>
                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M3.125 10H16.875" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                             <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                          </svg>
                                       </span>
                                    </div>
                                    <p className="text-light ">{chat.description}</p>
                                 </div>
                              </div>
                           )
                        })}
                     </div>
                  </div>}
                  <div id="accordion" className="footer-inner pb-5 footerSection">
                     <div className="container">
                        <div className="row">
                           <div className="col-sm-12 col-md-6 col-lg-3 footer-grid">
                              <div className="">
                                 <div className="" id="headingOne">
                                    <h5 className="mb-0">
                                       <button className="btn btn-link foo-head p-0 border-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       <span>
                                          {data.allWpPage.edges[0].node.FooterContent.footerMenu1}
                                       </span> 
                                       <i className="fa fa-angle-down" aria-hidden="true"></i>
                                       </button>
                                    </h5>
                                 </div>
                                 <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="card-body p-0">
                                       <ul className="list-unstyled">
                                          <li>
                                             <Link className="foo-text" to="/about"> Mission &amp; Vision</Link>
                                          </li>
                                          <li>
                                             <Link className="foo-text" to="/blog"> Blog</Link>
                                          </li>
                                          <li>
                                                <Link className="foo-text" to="/privacy"> Our Policy</Link>
                                          </li>  
                                          <li>
                                             <Link className="foo-text" to="/terms">Terms & Conditions</Link>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-12 col-md-6 col-lg-3 footer-grid">
                              <div className="">
                                 <div className="" id="headingTwo">
                                    <h5 className="mb-0">
                                       <button className="btn btn-link foo-head p-0 border-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       <span>{data.allWpPage.edges[0].node.FooterContent.footerMenu2}</span> 
                                       <i className="fa fa-angle-down" aria-hidden="true"></i>
                                       </button>
                                    </h5>
                                 </div>
                                 <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div className="card-body p-0">
                                       <ul className="list-unstyled">
                                          <li>
                                             <a className="foo-text" href="about-us.html">Custom App </a>
                                          </li>
                                          <li>
                                             <a className="foo-text" href="courses-listing.html"> Content managment System</a>
                                          </li>
                                          <li>
                                             <a className="foo-text" href="/">React development</a>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-12 col-md-6 col-lg-3 footer-grid">
                              <div className="">
                                 <div className="" id="headingThree">
                                    <h5 className="mb-0">
                                       <button className="btn btn-link foo-head p-0 border-0 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                       <span>{data.allWpPage.edges[0].node.FooterContent.footerMenu3}</span> 
                                       <i className="fa fa-angle-down" aria-hidden="true"></i>
                                       </button>
                                    </h5>
                                 </div>
                                 <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div className="card-body p-0">
                                       <ul className="list-unstyled">
                                          <li>
                                             <Link className="foo-text" to="/solutions/android">Android App</Link>
                                          </li>
                                          <li>
                                             <Link className="foo-text" to="/solutions/ios">Ipad</Link>
                                          </li>
                                          <li>
                                                <Link className="foo-text" to="/solutions/ios">Ios</Link>
                                          </li>
                                          <li>
                                             <Link className="foo-text" to="/">Hybrid app</Link>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-12 col-md-6 col-lg-3 footer-grid">
                              <div className="">
                                 <div className="" id="headingFour">
                                    <h5 className="mb-0">
                                       <button class="btn btn-link foo-head p-0 border-0 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                       <span>{data.allWpPage.edges[0].node.FooterContent.footerMenu4}</span> 
                                       <i className="fa fa-angle-down" aria-hidden="true"></i>
                                       </button>
                                    </h5>
                                 </div>
                                 <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                    <div className="card-body p-0">
                                       <ul class="list-unstyled">
                                          <li>
                                             <Link className="foo-text" to="/career">Current Opening</Link>
                                          </li>
                                          <li>
                                             <a className="foo-text" href="courses-listing.html"> life@teamlance</a>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <p className="w-100 copyright d-flex align-items-center justify-content-center m-0 pb-4">{data.allWpPage.edges[0].node.FooterContent.copyrightText}</p>
                </section>
              </footer>
            )
         }}
       />
    )
} 

export default Footer
