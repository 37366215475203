/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import Bar from "./bar";
import Header from "./header"
import Footer from "./footer";
import { Helmet } from "react-helmet"


const Layout = ({ children,...props }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
         <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
         <script
             src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"
             integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
             crossOrigin="anonymous">
          </script>
          <script defer={false} src={withPrefix('bootstrap.min.js')} type="text/javascript" />
      </Helmet>
      <Bar/>
      <Header siteTitle={data.site.siteMetadata.title} />
      {children}
      <Footer path={props.path}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
