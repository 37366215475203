//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {Link} from 'gatsby';
import down from '../assets/images/dwn.png';
import logo from '../assets/images/logo.svg';
import { StaticQuery, graphql } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <header className="header-bgcolor container home">
      <StaticQuery
         query={graphql`
          query{
            wpMenu(slug: {eq: "header-menu"}) {
               name
               slug
               menuItems {
                 nodes {
                   label
                   url
                   title
                   childItems {
                     nodes {
                       url
                       childItems {
                         nodes {
                           url
                           label
                           description
                           cssClasses
                           parentId
                           databaseId
                         }
                       }
                       label
                       description
                       cssClasses
                       parentDatabaseId
                     }
                   }
                   parentId
                   parentDatabaseId,
                   id
                 }
               }
             }
           }
         `}
         render={data => {
            return (
              <nav className="navbar navbar-expand-lg px-0 ">
                <Link className="navbar-brand" to="/">
                    <img style={{width:"185px", height:"100%"}} src={logo} alt="logo"/>
               </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                  <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M21 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7C4 7.26522 4.10536 7.51957 4.29289 7.70711C4.48043 7.89464 4.73478 8 5 8H21C21.2652 8 21.5196 7.89464 21.7071 7.70711C21.8946 7.51957 22 7.26522 22 7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6Z" fill="#004179"/>
                     <path d="M21.2143 0H0.785714C0.57733 0 0.37748 0.105357 0.23013 0.292893C0.0827803 0.48043 0 0.734784 0 1C0 1.26522 0.0827803 1.51957 0.23013 1.70711C0.37748 1.89464 0.57733 2 0.785714 2H21.2143C21.4227 2 21.6225 1.89464 21.7699 1.70711C21.9172 1.51957 22 1.26522 22 1C22 0.734784 21.9172 0.48043 21.7699 0.292893C21.6225 0.105357 21.4227 0 21.2143 0Z" fill="#004179"/>
                     <path d="M20.75 12H13.25C12.9185 12 12.6005 12.1054 12.3661 12.2929C12.1317 12.4804 12 12.7348 12 13C12 13.2652 12.1317 13.5196 12.3661 13.7071C12.6005 13.8946 12.9185 14 13.25 14H20.75C21.0815 14 21.3995 13.8946 21.6339 13.7071C21.8683 13.5196 22 13.2652 22 13C22 12.7348 21.8683 12.4804 21.6339 12.2929C21.3995 12.1054 21.0815 12 20.75 12Z" fill="#004179"/>
                  </svg>
               </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarColor01">
                  <ul className="navbar-nav mr-5">
                  {data &&
                     data.wpMenu &&
                     data.wpMenu.menuItems &&
                     data.wpMenu.menuItems.nodes &&
                     data.wpMenu.menuItems.nodes.length>0 && 
                     data.wpMenu.menuItems.nodes.map(
                        prop => {
                          return (
                            <li className={`nav-item ${prop && prop.childItems && prop.childItems.nodes.length>0?"dropdown has-megamenu":""}`}>
                                    {
                                      prop 
                                      && prop.parentId === null 
                                      && prop.childItems 
                                      && prop.childItems.nodes 
                                      && prop.childItems.nodes.length>0?(
                                      <React.Fragment>
                                          <a className="nav-link dropdown-toggle" href="/" onClick={(e)=>{e.preventDefault();}} data-toggle="dropdown">
                                             {prop.label} <img alt="down" class="ml-2" src={down} /> 
                                             <div id="triangle-up"></div>
                                           </a>
                                           <div className="dropdown-menu megamenu" role="menu">
                                             <div className="row">
                                                {prop.childItems.nodes.map((child, key) => {
                                                    return(
                                                      <div key={key} className="col-sm-6 col-md-4 col-lg-3">
                                                         <div className="col-megamenu">
                                                            <h6 className="title">{child.title}</h6>
                                                            <ul className="list-unstyled">
                                                               {
                                                                  child 
                                                                  && child.childItems 
                                                                  && child.childItems.nodes.length>0
                                                                  && child.childItems.nodes.map((menudata, key)=>{
                                                                     return(
                                                                        <li key={key}>
                                                                           <Link to={`/solutions/${menudata.label.toLowerCase()}`}>
                                                                              <span className="m-ico mr-3">
                                                                                 <img style={{height:"27px", width:"22px"}} src={`/${menudata.cssClasses[0]}.svg`} alt="icon" />
                                                                              </span>
                                                                              <div>
                                                                                 {menudata.label}
                                                                                 <p className="mb-0">{menudata.description}</p>
                                                                              </div>
                                                                           </Link>
                                                                         </li>
                                                                     ) 
                                                                  })
                                                               }
                                                            </ul>
                                                         </div>
                                                      </div>
                                                    )
                                                })}
                                             </div>
                                          </div>
                                       </React.Fragment>
                                     ):(
                                       prop.parentId === null && prop.id!=="cG9zdDoxMDE="?(
                                          <Link className="nav-link" to={`${prop.url}`}>{prop.label}</Link>
                                        ):null
                                     )
                                 }  
                            </li>
                          )
                        }
                      )}
                  </ul>
                  <ul className="list-unstyled d-flex  r-nav mb-0 ml-5">
                  {data &&
                     data.wpMenu &&
                     data.wpMenu.menuItems &&
                     data.wpMenu.menuItems.nodes &&
                     data.wpMenu.menuItems.nodes.length>0 && 
                     data.wpMenu.menuItems.nodes.map(
                        prop => {
                           return(
                              prop.id === "cG9zdDoxMDE="?(<li className="nav-item"> 
                                 <Link className="text-white btn-bg quote-btn btn-hover btn--beta btn btn-custom " to={`/quote`}> <span>Get a quote</span></Link>
                              </li>):null
                           )
                     })}
                  </ul>
               </div>
            </nav>  
            )
         }}
      />
 </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
